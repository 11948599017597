import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { _Table } from 'edviron-ui-package';
import { GET_TRUSTEES,GET_ONBOARDER_TRUSTEE } from '../School/Queries';
import Pagination from '../../../../components/Pagination/Pagination';
import { Link } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

const Erps = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [user_id, setUser_id] = useState<string | undefined>();
    const pageSize = 5; // Adjust as needed
    const userId=localStorage.getItem('user_id')
    
      

       // Fetch the trustees data using useQuery
       console.log(user_id,'user-id');
       
    const { loading, error, data, refetch } = useQuery(GET_ONBOARDER_TRUSTEE, {
        variables: { page: currentPage, onboarderId: userId },
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    const trustees = data.getAllErpOfOnboarder.trustee;
    const totalPageNo = data.getAllErpOfOnboarder.totalPages;

    return (
        <div className='w-full p-4'>
           
            <div className='w-full p-4 '>
                {data && (
                    <_Table
                        heading={"ERP List"}
                        className="lowercase"
                        data={[
                            [<div className='capitalize'>ERP Legal Name</div>, "Email"],
                            ...trustees.map((trustee: any) => [
                                <div
                                    key={trustee._id}
                                    className="w-full flex px-4 items-center uppercase"
                                >
                                    <Link to={`/trustee-school/${trustee._id}`}>{trustee.name}</Link>
                                </div>,
                                <div
                                    key={trustee._id}
                                    className="w-full flex px-4 items-center lowercase"
                                >
                                    {trustee.email_id}
                                </div>,
                                


                            ]),
                        ]}
                        footer={
                            totalPageNo && (
                              <Pagination
                              page={currentPage}
                              setPage={setCurrentPage}
                              totalPageNo={totalPageNo}
                              />
                            )
                          }

                    />
                )}
            </div>


        </div>
    );
};

export default Erps;
