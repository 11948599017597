import React, { useEffect, useState } from 'react';
import { Input, Modal, preventNegativeValues, preventPasteNegative } from 'edviron-ui-package';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import {
	GET_ONBOARDER,
	ONBOARDER_SCHOOL_LOGIN,
	GET_TRUSTEE_SCHOOL,
	ASSIGN_SCHOOL,
	SENT_KYC_INVITE,
	GET_KYC_DOCUMENTS,
	SEND_MAIL_TO_CASHFREE,
	VALIDATE_USER_QUERY,
} from '../School/Queries';

import { _Table } from 'edviron-ui-package';
import { Link, useNavigate } from 'react-router-dom';
import { Form } from 'edviron-ui-package';
import { useParams } from 'react-router-dom';
import Pagination from '../../../../components/Pagination/Pagination';
import { FaRegClipboard } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Schools from '../School/pages/Schools';
import SchoolsUploadViaCsv from './SchoolsUploadViaCsv';
import { IoIosArrowDropleft } from 'react-icons/io';
import { IoIosArrowDropright } from 'react-icons/io';

import loadIcond from '../../../../assets/loading-purple.svg';

const ErpSchools = () => {
	const [page, setPage] = useState(1);
	const { id } = useParams();
	const [KYCSchoolId, setKYCSchoolId] = useState(null);
	const [sendDocsMail, { loading: sendDocsMail_loading, error: sendDocsMail_error }] =
		useMutation(SEND_MAIL_TO_CASHFREE);
	const [schoolDocuments, { loading: schoolDocuments_loading, error: schoolDocuments_error }] =
		useLazyQuery(GET_KYC_DOCUMENTS);

	// const { data, loading, error } = useQuery(GET_ONBOARDER);
	const { data, loading, error } = useQuery(GET_TRUSTEE_SCHOOL, {
		variables: { page, trusteeId: id },
		errorPolicy: 'ignore',
	});

	const [validateAdminRefetch, { data: validateAdmin, loading: validateAdminLoading }] = useLazyQuery(
		VALIDATE_USER_QUERY,
		{
			variables: { schoolId: KYCSchoolId },
		},
	);

	const [sentKYCInviteMutation] = useMutation(SENT_KYC_INVITE);

	const [assignSchoolMutation, { data: result, loading: LOAD, error: _ERROR }] = useMutation(ASSIGN_SCHOOL, {
		refetchQueries: [
			{
				query: GET_TRUSTEE_SCHOOL,
				variables: { page, trusteeId: id },
			},
		],
		errorPolicy: 'ignore',
	});
	const [onboarder_school_login] = useMutation(ONBOARDER_SCHOOL_LOGIN);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [showModal, setShowModal] = React.useState(false);
	const [showUpdateModal, setShowUpdateModal] = React.useState(false);

	const schools = data && data.getTrusteeSchool ? data.getTrusteeSchool.schools : [];
	const totalPageNo = data && data.getTrusteeSchool ? data.getTrusteeSchool.total_pages || null : null;

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const handleResendLinkClick = async (id: string, name: string) => {
		try {
			const { data } = await sentKYCInviteMutation({
				variables: {
					school_id: id,
					school_name: name,
				},
			});

			toast.success('Invitation link resent successfully!');
		} catch (error) {
			toast.error('Error resending invitation link.');
		}
	};

	const handleKYCDashboard = async (school_id: any) => {
		setKYCSchoolId(school_id);
		console.log(school_id);

		const res = await validateAdminRefetch({ variables: { schoolId: school_id } });
		if (res.data.validateUserAdmin.token) {
			window.location.replace(
				`${process.env.REACT_APP_KYC_DASHBOARD_URL}/admin?token=${res.data.validateUserAdmin.token}`,
			);
		}
		console.log(res, 'response');
	};

	const [viewDocOpen, setViewDocOpen] = useState(false);
	const [docs, setDocs] = useState<any>([]);
	const [currDoc, setCurrDoc] = useState(1);

	const [loadingTitle, settLoadingTitle] = useState('');
	const [loadingVal, setLoadingVal] = useState(false);

	return (
		<div className='w-full p-4'>
			<SchoolsUploadViaCsv trusteeId={id} />
			<button
				onClick={() => setShowModal(!showModal)}
				className='px-4 py-2 mb-5 bg-[#6F6AF8] rounded-md text-white float-right'
			>
				Add Education Institute
			</button>
			<div>
				{data && (
					<_Table
						heading={'Institute List'}
						data={[
							['Schools', 'KYC Status', 'Action', 'Send mail to cashfree', 'View Doc', 'KYC DashBoard'],

							...schools.map((school: any) => [
								<div key={school.schoolId} className='w-full flex px-4 items-center'>
									{school.merchantName}
								</div>,
								<div key={school.schoolId} className='w-full flex px-4 items-center'>
									{school.fullKycStatus !== null ? school.fullKycStatus : 'Not initiated'}
								</div>,
								<div>
									<button
										className='px-4 py-2 bg-violet-600 text-white font-semibold rounded-lg'
										onClick={() => handleResendLinkClick(school.schoolId, school.merchantName)}
									>
										Resend Link
									</button>
								</div>,
								<div>
									<button
										className='px-4 py-2 bg-violet-600 text-white font-semibold rounded-lg'
										onClick={async () => {
											try {
												setLoadingVal(true);
												settLoadingTitle('Sending mail...');

												const data = await sendDocsMail({
													variables: {
														school_id: school.schoolId,
													},
												});

												setLoadingVal(false);

												toast.success('Mail sent successfully');
											} catch (err) {
												console.log(err);
											}
										}}
									>
										Send mail
									</button>
								</div>,
								<div>
									<button
										className='px-4 py-2 bg-violet-600 text-white font-semibold rounded-lg'
										onClick={async () => {
											const data = await schoolDocuments({
												variables: {
													school_id: school.schoolId,
												},
											});

											if (!data.error) {
												let temp: any = [];
												Object.keys(data.data.schoolDocuments).map((key) => {
													temp.push({ name: key, url: data.data.schoolDocuments[key] });
												});

												setDocs(temp);

												setViewDocOpen(true);
												setCurrDoc(1);
											}
										}}
									>
										View Doc
									</button>
								</div>,
								<div>
									<button
										className='px-4 py-2 bg-violet-600 text-white font-semibold rounded-lg'
										onClick={() => {
											handleKYCDashboard(school.schoolId);
										}}
									>
										KYC DASHBOARD
									</button>
								</div>,
							]),
						]}
						footer={totalPageNo && <Pagination page={page} setPage={setPage} totalPageNo={totalPageNo} />}
					/>
				)}
			</div>

			<Modal open={loadingVal} setOpen={setLoadingVal} title={loadingTitle}>
				<div className='flex align-item-center justify-center'>
					<img src={loadIcond} alt='' />
				</div>
			</Modal>

			<Modal open={viewDocOpen} setOpen={setViewDocOpen} title={docs[currDoc]?.name || ''}>
				<div className='flex justify-center items-center'>
					<IoIosArrowDropleft
						size={30}
						onClick={() => {
							if (currDoc === 1) setCurrDoc(docs.length - 1);
							else setCurrDoc(currDoc - 1);
						}}
					/>
					<iframe src={docs[currDoc]?.url + '#toolbar=0'} width='100%' height='500px' title='PDF Viewer'></iframe>
					<IoIosArrowDropright
						size={30}
						onClick={() => {
							if (currDoc === docs.length - 1) setCurrDoc(1);
							else setCurrDoc(currDoc + 1);
						}}
					/>
				</div>
			</Modal>

			<Modal className='max-w-lg w-full' open={showModal} setOpen={setShowModal} title='Create Education Institute'>
				<Form
					onSubmit={async (data: any) => {
						const schoolInput = {
							name: data['Education Institute'],
							email: data['Email'],
							phone_number: data['Phone Number'],
							trustee_id: id,
						};
						const res = await assignSchoolMutation({
							variables: {
								input: schoolInput,
							},
						});
						if (res.data) {
							toast.success('New School created successfully');
						}

						setShowModal(false);
					}}
				>
					<Input
						type='name'
						placeholder='Enter Institute Name'
						name='Education Institute'
						add_error={() => {}}
						required
					/>
					<Input type='email' name='Email' placeholder='Enter Email' add_error={() => {}} required />
					<Input
						type='number'
						name='Phone Number'
						add_error={() => {}}
						placeholder='Enter Phone no'
						onKeyDown={preventNegativeValues}
						onPaste={preventPasteNegative}
						min={0}
						maxLength={10}
						required
					/>

					<div className='mt-2 mb-2 text-center'>
						<button className='py-2 px-16 max-w-[15rem] w-full rounded-lg disabled:bg-blue-300 bg-[#6F6AF8] text-white'>
							Create Institute
						</button>
					</div>
				</Form>
			</Modal>
		</div>
	);
};

export default ErpSchools;
