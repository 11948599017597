import { gql } from '@apollo/client';

export const GET_ONBOARDER = gql`
	query Onboarder {
		onboarder {
			_id
			createdAt
			email
			name
			phone_number
			updatedAt
			schools {
				_id
				email_id
				createdAt
				name
				number_of_sections
				number_of_students
				phone_number
				student_count
				logo_url
				leed {
					_id
					onboarder_id
					status
				}
			}
		}
	}
`;

export const ONBOARDER_SCHOOL_LOGIN = gql`
	mutation Onboarder_school_login($school_id: String!) {
		onboarder_school_login(school_id: $school_id)
	}
`;

export const GET_SCHOOLS_PARENTS = gql`
	query Onboarder {
		onboarder {
			_id
			schools {
				_id
				parents {
					_id
					status
					phone_number
					student {
						name
					}
				}

				name
			}
		}
	}
`;

export const UPDATE_LEED_STATUS = gql`
	mutation Update_leed_status($leed_id: String!, $status: String!) {
		update_leed_status(leed_id: $leed_id, status: $status)
	}
`;

export const SEND_ONBOARDING_MASSAGE_TO_PARENTS = gql`
	mutation SendOnboardingMessage($school_id: String!) {
		sendOnboardingMessage(school_id: $school_id)
	}
`;

export const SCHOOL_LOGO_UPLOAD = gql`
	mutation UploadSchoolLogo($image_data: String!, $school_id: String!) {
		uploadSchoolLogo(image_data: $image_data, school_id: $school_id)
	}
`;

// replace by get trustee Schools
export const GET_SCHOOLS = gql`
  query GetSchools($page: Int) {
    getSchoolQuery(page: $page) {
      schools {
        school_id
        school_name
        pg_key
      }
      total_pages
      page
    }
  }
`;

// replace by get Onboarder Trustees
export const GET_TRUSTEES = gql`
  query GetTrustees($page: Int = 1, $pageSize: Int = 10) {
    getAllTrustee(page: $page, pageSize: $pageSize) {
      data {
        _id
        name
        email_id
        school_limit
        phone_number
      }
      page
      pageSize
      totalPages
      totalItems
    }
  }
`;

export const GET_TRUSTEE_SCHOOL = gql`
  query GetTrusteeSchool($page: Int=1, $trusteeId: String!) {
    getTrusteeSchool(page: $page, trustee_id: $trusteeId) {
		page
        total_pages
        schools{
          fullKycStatus
            merchantName
            merchantStatus
            minKycStatus
            schoolId
        } 
    }
  }
`;


export const ASSIGN_SCHOOL = gql`
  mutation CreateTrusteeSchool($input: SchoolInput!) {
    createTrusteeSchool(input: $input) {
      pg_key
      school_id
      trustee_id
    }
  }
`;

export const BULK_SCHOOL_UPLOAD = gql`
mutation CreateBulkTrusteeSchool($input: [SchoolInputBulk!]!, $trustee_id: String!) {
  createBulkTrusteeSchool(input: $input, trustee_id: $trustee_id)
}
`

export const GET_ONBOARDER_TRUSTEE = gql`
  query GetAllErpOfOnboarder($page: Int=1,$onboarderId: String!) {
    getAllErpOfOnboarder(page: $page,,onboarder_id: $onboarderId) {
      page
      total_pages
      trustee {
        _id
        email_id
        name
        onboarder_id
        phone_number
        school_limit
      }
    }
  }
`;

export const SENT_KYC_INVITE = gql`
  mutation SentKYCInvite($school_id: String!, $school_name: String!) {
    sentKYCInvite(school_id: $school_id, school_name: $school_name) 
     
  }
`; 


export const SEND_MAIL_TO_CASHFREE = gql`
  mutation sendDocsMail(
    $school_id: String!
  ){
    sendDocsMail(
      school_id: $school_id
    )
  }
`

export const GET_KYC_DOCUMENTS = gql`
  query schoolDocuments(
    $school_id: String!
  ){
    schoolDocuments(
      school_id: $school_id
    ){
      businessProof
      entityPan
      affiliation
      authPan
      bankProof
      aadhar
    }
  }

`


export const VALIDATE_USER_QUERY = gql`
  query ValidateUserAdmin($schoolId: String!) {
    validateUserAdmin(schoolId: $schoolId) {
      token
    }
  }
`;

