import React, { useState } from "react";
import Modal from "../../../../components/Modal/Modal";
import Form from "../../../../components/Form/Form";
import Input from "../../../../components/Input/Input";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import {BULK_SCHOOL_UPLOAD, GET_TRUSTEE_SCHOOL} from '../School/Queries';

const SchoolsUploadViaCsv: React.FC<{trusteeId: string | undefined} > = ({
 trusteeId
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isButtonActive, setIsButtonActive] = useState<boolean>(false);
  const [csvSchoolsList, setCsvSchoolsList] = useState<any[]>([]);

  const { data, loading, error } = useQuery(GET_TRUSTEE_SCHOOL, {
    variables: { page:1, trusteeId: trusteeId },
});

  const [createBulkTrusteeSchool,{ data: result, loading: LOAD, error: _ERROR }] = useMutation(BULK_SCHOOL_UPLOAD,{
    refetchQueries: [
        {
          query: GET_TRUSTEE_SCHOOL,
          variables: { page:1, trusteeId: trusteeId },
        },
      ],
});

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      toast.error("File not selected!");
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result as string;
      console.log(text);
      
      const rows = text.split("\n").map((row) => row.split(","));
      const headers = rows.shift();
      if (!headers) {
        return;
      }
      if (!file.name.endsWith(".csv")) {
        toast.error("Please select a CSV file!");
        return;
      }
      const trimmedHeaders = headers.map((header) => header.trim());
      const indices: { [key: string]: number } = {
        "School Name": headers.indexOf("School Name"),
        "School Email": headers.indexOf("School Email"),
        "Phone Number": trimmedHeaders.indexOf("Phone Number".trim()),
      };

      console.log(indices,'idices');
      
      const extractedData = rows
  .filter((row) => row.length === headers.length)
  .map((row) => ({
    "name": row[indices["School Name"]],
    "email": row[indices["School Email"]],
    "phone_number": row[indices["Phone Number"]].trim(), // Trim the phone number value
  }));
      console.log(extractedData,'extractedData');
      setCsvSchoolsList(extractedData);
      setIsButtonActive(true);
    };
    
    reader.readAsText(file);
    setIsButtonActive(false);
  };

  const createSchoolsViaCsv = async (input: any[]) => {
    const { data } = await createBulkTrusteeSchool({
      variables: { input,  trustee_id: trusteeId },
    });
    toast.success(data?.createBulkTrusteeSchool)
    
  };
  
  return (
    <div>
      <button
        className="mx-2 px-4 py-2 mb-5 bg-[#6F6AF8] rounded-md text-white float-right"
        onClick={() => setOpen(true)}
      >
        Bulk Upload
      </button>
      <Modal
        title="Upload CSV File ( Bulk Data )"
        open={open}
        setOpen={setOpen}
      >
        <div className="flex w-full">
          <div className="mx-auto">
            <Form
              onSubmit={async (data: any) => {
                console.log(csvSchoolsList);
                
                await createSchoolsViaCsv(csvSchoolsList);
                setOpen(false);
              }}
              className=""
            >
              <Input
                add_error={() => { }}
                type="file"
                name="Upload CSV File"
                onChange={(e: any, v: React.ChangeEvent<HTMLInputElement>) => {
                  handleFileUpload(v);
                }}
                label="CSV File"
              />
              <button
                type="submit"
                className="m-2 mt-6 bg-[#6F6AF8] text-white rounded-lg font-medium py-2 px-5"
                disabled={!isButtonActive}
              >
                Upload
              </button>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default SchoolsUploadViaCsv;