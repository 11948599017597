/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from 'react';
import { Select, _Table } from 'edviron-ui-package';
import PiaChart from '../components/PiaChart';
import { useMutation, useQuery } from '@apollo/client';
import {
	GET_ONBOARDER,
	GET_SCHOOLS_PARENTS,
	SCHOOL_LOGO_UPLOAD,
	SEND_ONBOARDING_MASSAGE_TO_PARENTS,
	UPDATE_LEED_STATUS,
} from '../Queries';
import { useLocation } from 'react-router-dom';
import Pagination from '../../../../../components/Pagination/Pagination';
import { toast } from 'react-toastify';
function ParentsList() {
	const { data, loading, error } = useQuery(GET_SCHOOLS_PARENTS);
	const [update_leed_status] = useMutation(UPDATE_LEED_STATUS, { refetchQueries: [{ query: GET_ONBOARDER }] });
	const [send_massage_to_parents] = useMutation(SEND_ONBOARDING_MASSAGE_TO_PARENTS);
	let { state } = useLocation();
	//state?.school?.leed?._id;
	const school = data?.onboarder?.schools?.filter((school: any) => school._id === state.school._id);

	const [currentPage, setCurrentPage] = useState(1);
	const [leedStatus, setLeedStatus] = useState({
		name: state?.school?.leed?.status || 'Leed',
	});
	const [selectedImage, setSelectedImage] = useState<any>('');
	const [uploadSchoolLogo, { loading: isUploading, error: error_in_logo }] = useMutation(SCHOOL_LOGO_UPLOAD, {
		onError: (err: any) => {
			toast(err);
		},
	});
	const validFileTypes = ['image/jpg', 'image/jpeg', 'image/png'];
	const maxSize = 2 * 1024 * 1024;
	const handleImageChange = (e: any) => {
		const file = e.target.files[0];

		if (file) {
			const file = e.target.files[0];
			if (!validFileTypes.find((type) => type === file.type)) {
				console.log('File must be in JPG/PNG format');
				return;
			}
			if (file.size > maxSize) {
				console.log('file size is larger than maxSize');
			}

			const reader = new FileReader();
			reader.onload = (e) => {
				setSelectedImage(e?.target?.result);
			};
			reader.readAsDataURL(file);
		}
	};
	if (error_in_logo) {
		toast.error(error_in_logo?.message);
	}

	useEffect(() => {
		if (selectedImage !== '') {
			const res = uploadSchoolLogo({
				variables: {
					image_data: selectedImage,
					school_id: school[0]._id,
				},
			});
		}
	}, [selectedImage]);

	const totalSudents = loading ? 0 : school[0]?.parents?.length;
	const perPage = 20;
	const totalPages = Math.ceil(totalSudents / perPage);
	const indexOfLastItem = currentPage * perPage;
	const indexOfFirstItem = indexOfLastItem - perPage;
	const currentItems = loading ? 0 : school[0]?.parents.slice(indexOfFirstItem, indexOfLastItem);
	if (loading) return <div>Loading...</div>;

	return (
		<div className='px-3'>
			<div className='mb-8 bg-white rounded-lg p-4 w-full flex justify-between items-center'>
				<div className='group overflow-hidden   flex-none w-28 h-28 flex justify-center relative items-center rounded-full bg-gray-100'>
					{isUploading ? (
						<i className='fa-solid fa-spinner text-4xl animate-spin'></i>
					) : (
						<>
							{state?.school?.logo_url ? (
								<img src={state?.school?.logo_url} alt='' className='w-full rounded-full h-full object-contain' />
							) : (
								<i className='fa-solid fa-school-flag text-5xl'></i>
							)}{' '}
						</>
					)}
					<label
						htmlFor='formFileSm'
						className='w-28 h-[40%] hidden cursor-pointer group-hover:flex rounded-b-full bottom-0  justify-center items-center bg-gray-900 bg-opacity-25 absolute'
					>
						<div>
							<input id='formFileSm' type='file' accept='image/*' hidden onChange={handleImageChange} />
						</div>
						<i className='fa-solid fa-camera text-white text-xl'></i>
					</label>
				</div>
				<h2 className=' text-4xl font-bold text-[#2B3674]'>{state?.school?.name || 'School'}</h2>
				<div className='flex items-center space-x-3'>
					{state?.school?.leed && (
						<Select
							label='Update Leed Status'
							className=' max-w-[15rem] w-full inline-flex'
							selected={leedStatus}
							setSelected={async (e: any) => {
								setLeedStatus(e);

								update_leed_status({
									variables: {
										leed_id: state?.school?.leed?._id,
										status: e.name,
									},
								});
								toast.success('Status Updated..');
							}}
							options={[
								'CREDENTIALS_SEND',
								'DATABASE_UPLOADED',
								'FEE_STRUCTURE_UPLOADED',
								'ONBOARDED',
								'READY_FOR_ONBOARDING',
							].map((status: any) => {
								return { name: status };
							})}
						/>
					)}
					{school[0]?.parents?.length > 0 && (
						<button
							onClick={() => {
								send_massage_to_parents({
									variables: {
										school_id: state?.school._id,
									},
								});
							}}
							className='bg-[#4318FF] mt-8 max-w-fit w-full shrink-0 px-4 py-1.5 rounded-lg text-white '
						>
							Send Onboarding Message
						</button>
					)}
				</div>
			</div>
			<PiaChart massageData={data?.onboarder?.schools[0]?.parents} />
			{school[0]?.parents.length > 0 && (
				<_Table
					heading={'Students List'}
					data={[
						['Name', 'Phone Number', 'Message Status'],
						...currentItems.map((d: any) => [
							<div>{d.student?.name}</div>,

							<div>{d?.phone_number}</div>,
							<div
								className={
									'px-4 Capitalize rounded-full py-1  max-w-fit ' +
									(d.status === 'read'
										? ' bg-[#e6faee] text-[#1a9a3d]'
										: d.status === 'delivered'
										? '  bg-[#ecfbfd] text-[#04788a]'
										: d.status === 'NOT_SENT'
										? '  bg-[#fff3f0] text-[#863e2b]'
										: ' bg-[#f7f7f7] text-[#6b6c7d]')
								}
							>
								● {d.status}
							</div>,
						]),
					]}
					footer={totalPages && <Pagination page={currentPage} setPage={setCurrentPage} totalPageNo={totalPages} />}
				/>
			)}
		</div>
	);
}

export default ParentsList;
