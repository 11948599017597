import { Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Dashboard from '../src/pages/Dashboard/Dashboard';
import Login from './pages/LogInPage/Login';
import { ApolloClient, createHttpLink, InMemoryCache, ApolloProvider, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ToastContainer, toast } from 'react-toastify';
import { onError } from '@apollo/client/link/error';
import 'react-toastify/dist/ReactToastify.css';
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";


loadDevMessages();
loadErrorMessages();   
const httpLink = createHttpLink({
	uri: process.env.REACT_APP_BACKEND_URL + '/graphql',
});

const authLink = setContext((_, { headers }) => {
	const token = localStorage.getItem('token');

	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : '',
		},
	};
});
const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors) graphQLErrors.forEach(({ message, locations, path }) => toast.error(message));

	if (networkError) toast.error(networkError);
});

const client = new ApolloClient({
	link: from([errorLink, authLink.concat(httpLink)]),
	cache: new InMemoryCache(),
});
function App() {
	const [user, set_user] = useState(null);
	const token = localStorage.getItem('token');
	useEffect(() => {
		if (token) {
			set_user(token);
		}
	}, []);
	return (
		<div className='App '>
			<ApolloProvider client={client}>
				<Routes>
					<Route path='*' element={<Dashboard user={user} set_user={set_user} />} />
					<Route path='/login' element={<Login user={user} set_user={set_user} />} />
				</Routes>
				<ToastContainer
					position='top-center'
					autoClose={2000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme='light'
				/>
			</ApolloProvider>
		</div>
	);
}

export default App;
